import { Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import 'styles/components/SubHeader.scss';
import { btnOnClick } from '../../utils/contentProvider/function';

const SubHeader = ({
  productType,
  showVideoModal,
  activeSection,
  setActiveSection,
  isPricingPage,
  productName,
}) => {
  const [isSticky, setSticky] = useState(false);

  const getProduct = () => {
    if (productName === 'Customer Feedback') {
      return 'chat';
    }
    if (productName === 'Mobile App Feedback') {
      return 'mobile';
    }
    return 'web';
  };

  const idsForButton = {
    'Website Feedback Tool': 'nav-get-started-wft',
    'Video Annotation': 'nav-get-started-vat',
    'PDF/Image Review': 'nav-get-started-pdf',
  };

  const scrollHandler = useCallback(() => {
    setSticky(window.pageYOffset > 36);
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  return (
    <>
      <header
        style={isSticky && !showVideoModal ? { zIndex: 9 } : { zIndex: 8 }}>
        <nav
          className={cn([
            'product-nav',
            {
              isSticky,
            },
          ])}>
          <div className="flex justify-content-between align-items-center header-margin-inline">
            <ul className="sub-header-left">
              <li className="flex-1">
                <div className="product-logo relative flex gap-4 align-items-center">
                  <Link to="/" className="logo-header hidden-mob">
                    <img
                      src="/assets/img/ruttl-logo.svg"
                      alt="ruttl logo"
                      title="ruttl logo"
                      width="78"
                      height="22"
                      className="ruttl-logo"
                    />
                  </Link>

                  <img
                    src={`/assets/img/${productType}-header-logo.svg`}
                    alt=""
                    width="36"
                    className="hidden-web"
                  />

                  {productName !== 'Bug Tracking' && (
                    <button type="button" className="product-name">
                      {productName}

                      <div className="products-menu">
                        <div
                          style={{
                            padding: '3px 10px',
                            textAlign: 'left',
                          }}>
                          Switch to:
                        </div>
                        <Link
                          to="/website-feedback-tool/"
                          className={
                            productName === 'Website Feedback Tool'
                              ? 'active'
                              : ''
                          }>
                          <div
                            className="nav-img-wrapper"
                            style={{ backgroundColor: '#f3f3f9' }}>
                            <img
                              src="/assets/img/website-feedback-icon.svg"
                              alt="pencil and paper icon designed by Brucira"
                              title="pencil and paper icon designed by Brucira"
                            />
                          </div>
                          <div className="nav-content">
                            <div className="nav-h4">Website Feedback</div>
                            <p>
                              Leave comments and real-time edits on live
                              websites.
                            </p>
                          </div>
                        </Link>

                        <Link
                          to="/mobile-app-feedback/"
                          className={
                            productName === 'Mobile App Feedback'
                              ? 'active'
                              : ''
                          }>
                          <div
                            className="nav-img-wrapper"
                            style={{ backgroundColor: '#f3f3f9' }}>
                            <img
                              src="/assets/img/mobile-app-feedback-icon.svg"
                              alt="pdf project icon designed by Brucira"
                              title="pdf project icon designed by Brucira"
                            />
                          </div>
                          <div className="nav-content">
                            <div className="nav-h4">Mobile App Feedback</div>
                            <p>Report issues from your mobile</p>
                          </div>
                        </Link>
                        <Link
                          to="/video-annotation-tool/"
                          className={
                            productName === 'Video Annotation' ? 'active' : ''
                          }>
                          <div
                            className="nav-img-wrapper"
                            style={{ backgroundColor: '#f3f3f9' }}>
                            <img
                              src="/assets/img/video-review.svg"
                              alt="video annotation icon designed by Brucira"
                              title="video annotation icon designed by Brucira"
                            />
                          </div>
                          <div className="nav-content">
                            <div className="nav-h4">
                              Video Annotation Tool
                              <span className="status-tag coming-soon">
                                New
                              </span>
                            </div>
                            <p>Review and collaborate on videos</p>
                          </div>
                        </Link>
                        <Link
                          to="/online-pdf-annotator-markup-software/"
                          className={
                            productName === 'PDF/Image Review' ? 'active' : ''
                          }>
                          <div
                            className="nav-img-wrapper"
                            style={{ backgroundColor: '#f3f3f9' }}>
                            <img
                              src="/assets/img/pdf-image.svg"
                              alt="pdf project icon designed by Brucira"
                              title="pdf project icon designed by Brucira"
                            />
                          </div>
                          <div className="nav-content">
                            <div className="nav-h4">PDF/Image Review</div>
                            <p>
                              Share feedback and leave comment on PDFs and image
                              files.
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/customer-feedback-tool/"
                          className={
                            productName === 'Customer Feedback' ? 'active' : ''
                          }>
                          <div
                            className="nav-img-wrapper"
                            style={{ backgroundColor: '#f3f3f9' }}>
                            <img
                              src="/assets/img/customer-feedback-icon.svg"
                              alt="customer feedback project icon designed by Brucira"
                              title="customer feedback project icon designed by Brucira"
                            />
                          </div>
                          <div className="nav-content">
                            <div className="nav-h4">Customer Feedback</div>
                            <p>
                              Chat with your website users in real time, &
                              resolve their issues
                            </p>
                          </div>
                        </Link>
                      </div>
                    </button>
                  )}
                  {/* 
                  {productName !== 'Bug Tracking' && (
                    <button className="switch-button" type="button">
                      <svg
                        style={{ verticalAlign: 'middle' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#150546"
                        strokeWidth="2">
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    </button>
                  )} */}

                  {/* {showOptions && (
                    <button
                      type="button"
                      onClick={() => setShowOptions(false)}
                      className="absolute-inset hidden-web"
                      aria-label="close products menu"
                      style={{
                        position: 'fixed',
                        cursor: 'default',
                        zIndex: '2',
                      }}></button>
                  )} */}
                </div>
              </li>
              <li className="vertical-line hidden-mob"></li>
              <li className="hidden-mob">
                <a
                  onClick={() => setActiveSection('how-it-works')}
                  className={activeSection === 'how-it-works' ? 'active' : ''}
                  href={`/${
                    productType !== 'video-annotation'
                      ? productType
                      : 'video-annotation-tool'
                  }/#how-it-works`}>
                  How it works
                </a>
              </li>
              <li className="hidden-mob">
                <a
                  onClick={() => setActiveSection('features')}
                  className={activeSection === 'features' ? 'active' : ''}
                  href={`/${
                    productType !== 'video-annotation'
                      ? productType
                      : 'video-annotation-tool'
                  }/#features`}>
                  Features
                </a>
              </li>
              {productName === 'Website Feedback Tool' && (
                <>
                  <li className="hidden-mob">
                    <a
                      onClick={() => setActiveSection('bug-tracking')}
                      className={
                        activeSection === 'bug-tracking' ? 'active' : ''
                      }
                      href="/website-feedback-tool/#bug-tracking">
                      Bug Tracking
                    </a>
                  </li>
                </>
              )}
              {productType !== 'video-annotation' &&
                /* eslint-disable indent */
                productType !== 'mobile-app-feedback' && (
                  <li className="hidden-mob">
                    <a
                      onClick={() => setActiveSection('reviews')}
                      className={activeSection === 'reviews' ? 'active' : ''}
                      href={`/${productType}/#reviews`}>
                      Reviews
                    </a>
                  </li>
                )}
              {productType !== 'online-pdf-annotator-markup-software' &&
                /* eslint-disable indent */
                productType !== 'video-annotation' &&
                productName !== 'Bug Tracking' && (
                  <li>
                    <Link
                      className={isPricingPage ? 'active' : ''}
                      to={`/${productType}-pricing`}>
                      Pricing
                    </Link>
                  </li>
                )}
            </ul>
            <ul className="sub-header-right">
              <li className=" hidden-mob">
                <a
                  className="no-hover-effect"
                  href={`https://${getProduct()}.ruttl.com/signup`}
                  type="button">
                  Login
                </a>
              </li>

              <li className=" hidden-mob">
                <button
                  type="button"
                  className="button"
                  id={idsForButton[productName]}
                  onClick={btnOnClick({
                    to: `https://${getProduct()}.ruttl.com/signup`,
                  })}>
                  Get Started
                </button>
              </li>

              {/* {productName === 'Bug Tracking' && (
                <li className=" hidden-mob">
                  <button
                    type="button"
                    className="button"
                    onClick={() => toggleWaitlistModal()}>
                    Live Demo
                  </button>
                </li>
              )} */}
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

SubHeader.propTypes = {
  productType: PropTypes.string,
  showVideoModal: PropTypes.bool,
  activeSection: PropTypes.string,
  setActiveSection: PropTypes.func,
  isPricingPage: PropTypes.bool,
  productName: PropTypes.string,
};
export default SubHeader;
