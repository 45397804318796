import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// import { nanoid } from 'nanoid';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import FeatureContainer from '../components/FeatureContainer';

import 'styles/usecases.scss';
import SubHeader from '../components/SubHeader/SubHeader';
import NewFaq from '../components/NewFaq/NewFaq';

import FeaturesGrid from '../components/FeaturesGrid/FeaturesGrid';
import { MobileFeedbackFeatures } from '../utils/contentProvider';
import { faqDatabugTracking } from '../utils/contentProvider/faqDataCommon';
import DownloadAppModal from '../components/DownloadAppModal/DownloadAppModal';
import { btnOnClick } from '../utils/contentProvider/function';

const MobileAppFeedback = ({ location }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [showDownloadAppModal, setShowDownloadAppModal] = useState(false);

  const toggleDownloadAppModal = () => {
    setShowDownloadAppModal(!showDownloadAppModal);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const currentPosition = window.scrollY + window.innerHeight / 2;
      const position = window.pageYOffset;
      const downloadApp = document.querySelector('.download-app');

      if (position > 500) {
        downloadApp.classList.remove('static-download-app');
      } else {
        downloadApp.classList.add('static-download-app');
      }

      sections.forEach((section) => {
        if (
          section.offsetTop <= currentPosition &&
          section.offsetTop + section.offsetHeight > currentPosition
        ) {
          setActiveSection(section.getAttribute('id'));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // function toggleVideoModal() {
  //   setVideoModal(!showVideoModal);
  // }
  return (
    <Layout
      location={location}
      hideTopBar
      productPage
      hideHeader
      product="Bug Tracking">
      <Meta
        title="Best Mobile App Feedback Software - Ruttl"
        description="Bug Reporting & Tracking Tool for Mobile apps by Ruttl. Fastest way to report bugs directly on mobile application."
        url="https://ruttl.com/mobile-app-feedback/"
      />

      <SubHeader
        productType="mobile-app-feedback"
        productName="Mobile App Feedback"
        showVideoModal
        setActiveSection={setActiveSection}
        activeSection={activeSection}
      />
      <div className="download-app hidden-mob static-download-app">
        <button
          type="button"
          onClick={toggleDownloadAppModal}
          id="download-app-maf-stickyfooter"
          style={{
            backgroundColor: '#150546',
            border: 'none',
            borderRadius: '25px',
          }}
          className="button">
          <img src="/assets/img/play-store-logo.png" alt="playstore logo" />
          <span style={{ marginLeft: 10 }}>Download App</span>
        </button>
      </div>

      <DownloadAppModal
        showDownloadAppModal={showDownloadAppModal}
        toggleDownloadAppModal={toggleDownloadAppModal}
      />
      <main className="usecases-styled-main">
        <section className="usecase-hero-vertical">
          <div className="container">
            <div className="center">
              <h1 className="mx-auto" style={{ maxWidth: 730 }}>
                Bug tracking that works for mobile applications
              </h1>
              <p className="mx-auto" style={{ maxWidth: 650 }}>
                Fastest way to report bugs directly on mobile application.
                Create ticket, send them on desktop workflow and start tracking
                bugs
              </p>

              <div className="hidden-web">
                <a
                  href="https://play.google.com/store/apps/details?id=com.ruttl.app"
                  style={{ backgroundColor: '#150546', border: 'none' }}
                  id="download-app-maf-hero-mobile"
                  className="button">
                  <img
                    src="/assets/img/play-store-logo.png"
                    alt="playstore-logo"
                  />
                  <span style={{ marginLeft: 10 }}>Download App</span>
                </a>
              </div>
              <button
                className="button hidden-mob"
                type="button"
                id="get-started-free-maf-hero"
                onClick={btnOnClick({
                  to: 'https://mobile.ruttl.com/signup',
                })}>
                Get started for free
              </button>
              <a
                href="https://calendly.com/setup-demo-with-ruttl/30min"
                target="_blank"
                rel="noopener noreferrer"
                type="button"
                id="book-demo-maf-hero"
                className="button book-demo">
                Book a Demo
              </a>
              <p>
                <img src="/assets/img/apple-logo.png" alt="apple-logo" />
                <small style={{ marginLeft: 5 }}>Coming soon for iOS</small>
              </p>
            </div>

            <div className="usecase-vertical-asset center">
              <video
                src="https://static.ruttl.com/mobile-app-feedback-hero.mp4"
                autoPlay
                loop
                muted
                height={570}
                playsInline></video>
            </div>
          </div>
        </section>
        <section
          className="styled-bug-tracking"
          style={{ backgroundColor: '#fff' }}
          id="how-it-works">
          <div className="container">
            <h2 className="center" id="view-demo">
              Get started in 1 minute
            </h2>

            <div className="flex-2 flex flex-wrap align-items-stretch">
              <div className="wrapper">
                <div className="card-single">
                  <div className="card-block">
                    <div className="card-icon">
                      <img
                        loading="lazy"
                        src="/assets/img/add-plugin.svg"
                        alt="install snippet icon"
                        title="install snippet icon"
                      />
                    </div>
                    <div className="card-desc">
                      <h3>Download App</h3>
                      <p>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.ruttl.app"
                          target="_blank">
                          Download the Android app
                        </a>
                        &nbsp;from the Play Store and login with the same email
                        as that of your dashboard.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="card-single">
                  <div className="card-block">
                    <div className="card-icon">
                      <img
                        loading="lazy"
                        src="/assets/img/report-bugs.svg"
                        alt="adding tickets icon"
                        title="adding tickets icon"
                      />
                    </div>
                    <div className="card-desc">
                      <h3>Report Bugs</h3>
                      <p>
                        Activate the widget and start reporting bugs directly on
                        your app. It&apos;s just like Figma&apos;s commenting,
                        but on your mobile app.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="card-single">
                  <div className="card-block">
                    <div className="card-icon">
                      <img
                        loading="lazy"
                        src="/assets/img/track-progress.svg"
                        alt="manage with workflow icon"
                        title="manage with workflow icon"
                      />
                    </div>
                    <div className="card-desc">
                      <h3>Track Progress</h3>
                      <p>
                        These comments wil be added as tickets on your dashboard
                        and you can prioritise, assign and set deadlines -
                        similar to Jira.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="center">
                  <button
                    className="button"
                    type="button"
                    onClick={toggleVideoModal}>
                    See how it works
                  </button>
                </div> */}
          </div>
        </section>

        <section className="section-pad">
          <div className="container " style={{ marginTop: 0 }}>
            <div className="flex-row">
              <div className="col col-8 offset-2">
                <div className="feedback bug-track-feedback">
                  <div className="feebackImage">
                    <img
                      loading="lazy"
                      src="/assets/img/atlassian-logo-round.png"
                      alt="Atlassian Logo"
                      title="Atlassian Logo"
                    />
                  </div>
                  <div style={{ maxWidth: 560 }}>
                    <h3>
                      “We loved using ruttl. The entire process right from
                      sign-up to commenting & managing feedback is so smooth, we
                      decided to extend the license beyond a year to test it
                      with other programs too!
                    </h3>
                    <p>
                      <span>-Josh Shepherd,&nbsp;</span>
                      Event Tech Strategy at Atlassian
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="usecases-styled-main" id="features">
          <div className="section features-main bugtrack-features">
            <div className="container">
              <div className="section-head">
                <h2 className="center mx-auto" style={{ maxWidth: 700 }}>
                  No more screenshots or clumsy excel sheets to track bugs
                </h2>
              </div>

              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/report-bugs-app.png"
                          alt="report-bug"
                          title="report-bug"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">
                        Report bugs directly on mobile apps
                      </h3>
                      <p>
                        Collect bugs directly on your mobile app in the form of
                        comments so you do not lose the context. The comments
                        will get added in form of tickets to your dashboard.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/track-progress.png"
                          alt="track-progress"
                          title="track-progress"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">
                        Solve them faster with workflow
                      </h3>
                      <p>
                        Capture, assign, prioritize, and resolve bugs seamlessly
                        and collaboratively. Instead of workflow, you can also
                        integrate ruttl with tools like Jira, Trello, asana and
                        many more.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/collab-with-teams-app.png"
                          alt="collab-with-team"
                          title="collab-with-team"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">
                        Save time and work smarter with your team
                      </h3>
                      <p>
                        Each member of your team will get notified about these
                        bugs and you can then assign tasks, set deadlines and
                        prioritize them as per the need. Jira with fewer steps.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/integration-tools.png"
                          alt="Integrate with needed tools"
                          title="Integrate with needed tools"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">
                        Integrate with needed tools
                      </h3>
                      <p>
                        Receive notifications for new tickets through your
                        preferred tools such as Slack, Trello, Asana, Jira,
                        ClickUp or Zapier.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </div>

          <FeaturesGrid
            data={MobileFeedbackFeatures}
            productType="bug-tracking-tool"
            showDownloadAppModal={showDownloadAppModal}
            toggleDownloadAppModal={toggleDownloadAppModal}
            downloadApp
          />

          <NewFaq data={faqDatabugTracking} />
        </section>
      </main>
    </Layout>
  );
};
MobileAppFeedback.propTypes = {
  location: PropTypes.object,
};

export default MobileAppFeedback;
