import React from 'react';
import PropTypes from 'prop-types';

const DownloadAppModal = ({ showDownloadAppModal, toggleDownloadAppModal }) => (
  <div className={`download-app-modal ${showDownloadAppModal ? 'show' : ''}`}>
    <div className="modal-container">
      <button
        type="button"
        aria-label="close"
        className="button-unstyled close-button"
        onClick={() => toggleDownloadAppModal()}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
            d="M368 368L144 144M368 144L144 368"
          />
        </svg>
      </button>
      <div className="qr-container">
        <div className="img-container">
          <img alt="QR Code" className="" src="/assets/img/app-qr.png" />
        </div>
        <div className="headline">
          <h2>Download the app</h2>
          <p>Currently available for Android</p>
        </div>
        <p style={{ textAlign: 'center' }}>Scan this QR to download the app.</p>
      </div>
    </div>
  </div>
);

DownloadAppModal.propTypes = {
  toggleDownloadAppModal: PropTypes.func,
  showDownloadAppModal: PropTypes.bool,
};
export default DownloadAppModal;
